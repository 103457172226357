<template>
  <div class="title__flag d-flex align-end justify-center">
    <img width="30px" src="../assets/bandeira_vermelha_icon.png" alt="" />
    <div class="title">
      <p>
        {{ title }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: ["title"],
};
</script>

<style scoped>
.title__flag {
  position: relative;
  max-height: 30px;
}

.title {
  background-color: #c92f05;
  padding: 2px 10px;
  border-radius: 4px;
  margin-left: 5px;
}

.title p {
  color: white;
  margin-bottom: 0;
  white-space: nowrap;
}

@media (max-width: 600px) {
  .title p {
    white-space: normal;
    font-size: 0.8em;
  }
}
</style>
