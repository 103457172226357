<template>
  <section class="pb-4 container">
    <GoBack />
    <Breadcrumb class="sobre_section_bc-color">
      <template v-slot:breadcrumbLinks>
        <router-link
          :style="{
            color: color,
          }"
          tag="p"
          :to="{ name: link.href }"
          v-for="(link, idx) in breadcrumbItems"
          :key="idx"
        >
          {{ link.text }}
        </router-link>
      </template>
    </Breadcrumb>
    <v-row>
      <v-col
        class="d-flex align-center justify-center justify-md-start align-md-start mt-8 mb-md-4"
      >
        <FlagTitle title="Cartografia Social Familiar" />
      </v-col>
    </v-row>
    <v-row class="d-flex justify-center align-center mb-5">
      <v-col
        cols="12"
        md="6"
        class="d-flex justify-center align-center mx-auto mb-3"
      >
        <div class="section__container_card">
          <Card borderColor="#C92F05" titleColor="#C92F05">
            <template v-slot:conteudo>
              <p>
                A etapa subsequente à aplicação do formulário é a oficina de
                cartografia social familiar. Uma preocupação da Cáritas era que
                a condução das oficinas tivesse um formato que promovesse mais
                proximidade com as pessoas atingidas, assemelhando-se a uma
                conversa informal e, assim, permitisse que as famílias
                relembrassem a vida anterior ao rompimento nas comunidades, bem
                como a caracterização dos seus terrenos e as perdas sofridas.
                Foram criadas perguntas geradoras que contribuíram com o
                processo de rememoração do repertório de cada núcleo familiar.
                Refletindo sobre como contribuir para que as pessoas atingidas
                participassem ativamente do levantamento de suas perdas, a
                equipe pensou em maneiras de estimar medidas e quantidades, a
                exemplo de dimensões de cômodos, a partir de móveis e
                equipamentos ali existentes. Em um retorno imaginário pelos
                terrenos e quintais, as famílias contavam quantas frutíferas
                havia, quais espécies cultivavam, as hortaliças e os plantios de
                roças diversificadas, entre outros sistemas produtivos.
              </p>
              <p>
                A aplicação da metodologia de
                <a
                  target="_blank"
                  style="text-decoration: none"
                  href="http://www.monografias.ufop.br/handle/35400000/3979"
                  class="pink--text"
                  >cartografia social familiar</a
                >
                nos territórios atingidos atendeu satisfatoriamente ao propósito
                da complementação das perdas e dos danos declarados no
                formulário e para o levantamento de outros danos que o
                formulário não abrangia ou não permitia maior detalhamento. Os
                desenhos demonstram o domínio que possuem do território, as
                relações com a comunidade e, principalmente, o conhecimento
                sobre seus próprios terrenos e tudo o que havia neles.
              </p>
            </template>
          </Card>
        </div>
      </v-col>
      <v-col cols="12" md="6">
        <div
          class="assessoria_card d-flex flex-column justify-center align-center"
        >
          <img src="../../assets/cartografia_social_01.png" alt="" />
          <p style="font-size: 0.5em">
            Oficina de Cartografia Social. Foto: Acervo Cáritas, 2018.
          </p>
        </div>
      </v-col>
    </v-row>

    <v-row class="d-flex justify-center align-center mb-5 flex-md-row-reverse">
      <v-col
        cols="12"
        md="6"
        class="d-flex justify-center align-center mx-auto"
      >
        <div class="section__container_card">
          <Card
            title_alignment="center"
            borderColor="#C92F05"
            titleColor="#C92F05"
          >
            <template v-slot:conteudo>
              <p>
                O intuito de levantar as perdas sofridas por parte dos próprios
                atingidos, realizada pela instrumentalização que sua ATI
                proporciona, é o de munir as famílias de conhecimento e
                apropriação do processo de levantamento, assim como de todos os
                elementos identificados, qualificados e quantificados para que
                estejam cientes do que possuíam e foi perdido. Ressalta-se a
                importância desse processo de acionamento das memórias para que
                as pessoas atingidas tenham melhores condições de discutir e
                refletir sobre as propostas de indenização ofertadas pela
                Fundação Renova e para que possam avaliar se estão, de fato,
                sendo ressarcidas por suas perdas e se tudo o que ativamente
                registraram foi considerado em suas propostas.
              </p>
              <p>
                Além das perdas materiais e dos sistemas produtivos, o momento
                da cartografia traz lembranças e memórias afetivas, referências
                que orientaram suas vivências e experiências naquele território.
                A oficina de cartografia, então, também mapeia as emoções
                relacionadas com os lugares e com todo o território,
                extrapolando, assim, os terrenos individuais. O resgate das
                memórias atingidas levanta as perdas das estruturas públicas e
                particulares das comunidades, das dinâmicas socioeconômicas de
                cada uma e em relação às comunidades vizinhas, a partir das
                quais se estabeleceram redes de cooperação, de complementação e,
                de certo modo, autonomia territorial.
              </p>
            </template>
          </Card>
        </div>
      </v-col>
      <v-col cols="12" md="6">
        <div
          class="assessoria_card d-flex flex-column justify-center align-center"
        >
          <v-img
            max-width="100%"
            src="../../assets/cartografia_social_02.png"
            alt=""
          />
          <p style="font-size: 0.5em">
            Oficina de Cartografia Social. Foto: Acervo Cáritas, 2018.
          </p>
        </div>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-center align-center mb-5">
      <div class="d-flex flex-column justify-center align-center">
        <v-btn
          :to="{ name: 'sobre_vistoria_advi' }"
          small
          color="#E06919"
          class="white--text pa-5"
        >
          Conhecer "Vistoria e ADVI"
          <v-icon>mdi-arrow-right</v-icon>
        </v-btn>
      </div>
    </v-row>
  </section>
</template>

<script>
import Card from "@/components/Card.vue";
import Breadcrumb from "@/layout/Breadcrumb.vue";
import GoBack from "@/components/GoBack.vue";
import FlagTitle from "../../components/FlagTitle.vue";

export default {
  components: {
    Card,
    Breadcrumb,
    FlagTitle,
    GoBack,
  },
  data() {
    return {
      breadcrumbItems: [
        {
          text: "<       Quem somos       /",
          href: "cards",
        },
        {
          text: "      Assessoria Técnica      /",
          href: "sobre_assessoria_tecnica",
        },
        {
          text: "O Cadastro /",
          href: "sobre_cadastro",
        },
        {
          text: "Cartografia Social e Familiar",
          href: "sobre_cartografia_social_familiar",
        },
      ],
    };
  },
};
</script>

<style scoped>
h5 {
  color: #c92f05;
}

.card__footer_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  color: white;
}

.assessoria_card {
  padding: 2em;
}

.assessoria_card p {
  padding: 1em;
  text-align: center;
  font-size: 0.7em;
}

.assessoria__items {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 7em;
}

.assessoria__items p {
  text-align: center;
  font-size: 0.6em;
  max-width: 80%;
}
</style>
